<template>
  <RWCard>
    <template v-slot:title>
      <h3 class="kt-portlet__head-title">
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'kt-svg-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M19.925 8.44l-8.449 8.448a1.5 1.5 0 01-1.075.44l-3.523-.035a1.5 1.5 0 01-1.485-1.463l-.086-3.427a1.5 1.5 0 01.439-1.098l8.522-8.522a1.5 1.5 0 012.121 0l3.536 3.535a1.5 1.5 0 010 2.121z" fill="#000" fill-rule="nonzero"/><rect fill="#000" opacity=".3" x="5" y="20" width="15" height="2" rx="1"/></g></svg>
        Peraturan dan Ketentuan Program
      </h3>
    </template>

    <!-- body -->
    <template v-slot:body>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua.
      </p>

      <b-alert show variant="info">
        <ul>
          <li v-for="(row, id) in rule" :key="id">{{ row }}</li>
        </ul>
      </b-alert>

      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua.
      </p>
      <b-alert show variant="success">
        <ul>
          <li v-for="(row, id) in rule" :key="id">{{ row }}</li>
        </ul>
      </b-alert>
    </template>
  </RWCard>
</template>

<script>
import RWCard from "@/views/partials/content/Portlet.vue";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
export default {
  components: {
    RWCard
  },
  data() {
    return {
      item: 1,
      items: [
        { text: "Real-Time", icon: "mdi-clock" },
        { text: "Audience", icon: "mdi-account" },
        { text: "Conversions", icon: "mdi-flag" }
      ],
      rule: [
        "Lorem ipsum dolor sit amet, consectetur ",
        "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit",
        "Consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit"
      ]
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Afiliasi" },
      { title: "Peraturan" }
    ]);
  }
};
</script>
